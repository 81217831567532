  'use strict';

  angular.module('gridstack-angular', []);

  var app = angular.module('gridstack-angular');

  /** @ngInject */
  app.controller('GridstackController', ['$scope', function($scope) {

    var gridstack = null;

    this.init = function(element, options) {
      gridstack = element.gridstack(options).data('gridstack');
      return gridstack;
    };

    this.removeItem = function(element) {
      if(gridstack) {
        gridstack.removeWidget(element, false);
        // Needed for the remaining items to reorder according to available space.
        gridstack.batchUpdate();
        return gridstack.commit();
      }
      return null;
    };

    this.addItem = function(element) {
      if(gridstack) {
        gridstack.makeWidget(element);
        return element;
      }
      return null;
    };

  }]);